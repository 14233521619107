
$primary: #3e8ed0;
$warning: #FCC30B;

$navbar-item-hover-color: $warning;
$navbar-item-hover-background-color: rgba(0,0,0,0);
$navbar-item-active-color: $warning;
$navbar-item-color: #FFFFFF;
$navbar-background-color: #252C40;

@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
@import '../node_modules/bulma/bulma.sass';


* {
  font-family: 'Prompt', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.banner {
  height: 800px;
  width: 100%;
  background-image: url('./assets/banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.hover-card {
  cursor: pointer; 
  transition: 0.1s;
}

.hover-card:hover {
  // box-shadow: 0 0.5em 1em -0.125em rgba(62, 142, 208, 0.5), 0 0px 0 1px rgba(62, 142, 208, 0.5);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
}

.card-brand-product {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
}

.card-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  min-height: 180px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.1s;
  position: relative;
  overflow: hidden;
}

.card-product-loading {
  cursor: progress; 
  background-color: #eee;
  width: 180px;
  height: 250px;
  border-radius: 10px;
}

.card-product h5 {
  width: 90%;
  text-align: center;
  word-wrap: break-word;
  margin: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.card-product:hover {
  z-index: 5;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
  transform: translate(10px, -10px) !important;
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  background-color: black;
  transition: 0.1s;
  opacity: 0;
  z-index: 4;
}

.overlay:hover {
  opacity: 0.2;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track-piece {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px !important;
  }
}